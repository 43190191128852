<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header flex-wrap">
        <h1>Reports</h1>
        <span class="flex align-center flex-wrap">
          <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
        </span>
        
        
      </div>
      <div class="flex align-start flex-wrap">

        <router-link :to="{name: 'onboardingEmployees'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isOnboardingEmployees, 'btn__outlined': !isOnboardingEmployees }">Employees By Status</button>
          </router-link>

          <!-- <router-link :to="{name: 'payrollQueue'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPayrollQueue, 'btn__outlined': !isPayrollQueue }">Payroll Queue</button>
          </router-link> -->
          <!-- <router-link :to="{name: 'paidAssignments'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isPaidAssignments, 'btn__outlined': !isPaidAssignments }">Paid Assignments</button>
          </router-link>
          <router-link :to="{name: 'backgroundChecks'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isBackgroundChecks, 'btn__outlined': !isBackgroundChecks }">Background Checks</button>
          </router-link> -->
          <!-- <router-link :to="{name: 'payables'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isPayables, 'btn__outlined': !isPayables }">Payables</button>
          </router-link> -->

          <router-link :to="{name: 'reportTimesheets'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isTimesheets, 'btn__outlined': !isTimesheets }">Timesheet Reports</button>
          </router-link>
          
          <router-link :to="{name: 'userParse'}">
            <button class="btn mr-3" v-bind:class="{ 'btn__dark': isUserParse, 'btn__outlined': !isUserParse }">User Reports</button>
          </router-link>

      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'reportsHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isPayrollQueue() {
      return this.$route.name == 'payrollQueue';
    },
    isBackgroundChecks() {
      return this.$route.name == 'backgroundChecks';
    },
    isEvereeUsersWithoutExternalId() {
      return this.$route.name == 'evereeUsersWithoutExternalId';
    },
    isNonContractors() {
      return this.$route.name == 'nonContractors';
    },
    isOnboardingEmployees() {
      return this.$route.name == 'onboardingEmployees';
    },
    isTimesheets() {
      return this.$route.name == 'reportTimesheets';
    },
    
    isPayables() {
      return this.$route.name == 'payables';
    },
    isNY() {
      return this.$route.name == 'newYork';
    },


    


    
    isUserParse() {
      return this.$route.name == 'userParse';
    },
    // isSummary() {
    //   return this.$route.name == 'client';
    // },
    // isNotes() {
    //   return this.$route.name == 'clientNotes';
    // },
    // isVenues() {
    //   return this.$route.name == 'clientVenues';
    // },
    // isFiles() {
    //   return this.$route.name == 'clientFiles';
    // }
  },
  methods: {
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    console.log(this)
  }
}
</script>